import Pho from "../Components/UI/Photos/Pho";

const Photo = () => {
    return (
      <div>
        <Pho />
      </div>
    );
  };
  
  export default Photo;