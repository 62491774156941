import Des from "../Components/UI/Designs/Des";

const Design = () => {
    return (
      <div>
        <Des/>
      </div>
    )
  };
  
  export default Design;