import Broad from "../Components/UI/Broadcasts/Broad";

const Broadcasts = () => {
  return (
    <div>
      <h3>Broadcasts & Videography Work</h3>
      <Broad />
    </div>
  );
};

export default Broadcasts;
