import Cine from "../Components/UI/Cinefam/Cine";

const Cinefam = () => {
  return (
    <>
      <Cine />
    </>
  );
};

export default Cinefam;
